.container {
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid #f2f2f2;
}

.container img {
    margin: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

@media screen and (min-width: 641px) {
    .container {
        display: none;
    }
}