.videosContainer {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

iframe {
  display: block;
  width: 100%;
  margin-bottom: 25px;
}

@media screen and (min-width: 641px) {
  iframe {
    height: 300px;
  }
}

@media screen and (min-width: 1000px) {
  iframe {
    height: 400px;
  }
}

@media screen and (min-width: 1200px) {
  iframe {
    height: 500px;
  }
}

@media screen and (min-width: 1600px) {
  iframe {
    height: 600px;
  }
}

@media screen and (max-width: 640px) {
  iframe {
    height: 200px;
  }
  .videosContainer {
    width: 80%;
  }
}
