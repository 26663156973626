.mainContainer {
  z-index: 3;
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  margin-top: 0;
  background: #ffffff;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
}

.logo {
  width: 60%;
  text-align: center;
}

.logo img {
  width: 210px;
  height: 70px;
}

.navigationContainerDesktop {
  display: flex;
  justify-content: center;
  align-items: center;
}

.emptyDiv {
  width: 10%;
}

.buttonBar {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-self: center;
}

.socialButtons {
  display: inline-flex;
  width: 10%;
  justify-content: flex-end;
}

.socialButtons img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 2px;
}

.dropdownMenu {
  display: flex;
  justify-content: center;
}

.mobile {
  display: flex;
  align-items: center;
}

.mobile .logo {
  width: 80%;
}

.hamburgerIcon {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.hamburgerIcon img {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 960px) {
  .navigationContainerDesktop {
    display: none;
  }
  .logo {
    display: flex;
    justify-content: flex-start;
  }
  .logo img {
    width: 150px;
    height: 50px;
  }
}
@media screen and (min-width: 961px) {
  .hamburgerIcon,
  .mobDiv {
    display: none;
  }
  .logo {
    margin-left: auto;
    margin-right: auto;
  }
}
