.dropdownContainer {
  width: 210px;
  position: fixed;
  background-color: #ffffff;
  text-align: center;
  top: 120px;
}

.dropdownContainer ul {
  list-style-type: none;
  margin: 5px 2px 2px 2px;
  padding: 0;
}

.dropdownContainer ul span {
  display: block;
  color: #000000;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  background-color: #ffffff;
  padding: 10px 0;
  text-transform: uppercase;
  border: none;
  width: 100%;
}

.dropdownContainer ul span:hover {
  color: #f2de5f;
}
