.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  cursor: zoom-out;
}

.top {
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: row-reverse;
}

.content {
  width: 100%;
  height: 90%;
  display: flex;
}

.bottom {
  width: 100%;
  height: 5%;
  color: #b2b2b2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left,
.right {
  height: 100%;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.left img,
.right img,
.top img {
  cursor: pointer;
}

.photo img {
  cursor: default;
}

.photo {
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo iframe {
  height: 80vh;
}

.photo img {
  max-height: 100%;
  max-width: 100%;
}

@media screen and (max-width: 640px) {
  .top img {
    width: 30px;
    height: 30px;
  }
  .left,
  .right {
    display: none;
  }

  .photo {
    width: 100%;
  }

  .photo iframe {
    height: 90vh;
  }
}
