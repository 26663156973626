.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  gap: 10px;
  grid-auto-rows: minmax(400px, auto);
}

.photo {
  position: relative;
}

.description {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  bottom: 0;
  left: 0;
  color: #ffffff;
  cursor: pointer;
  display: none;
}

.photo:hover
.description {
  display: block;
}

.description p {
  margin-left: 10px;
}

.description p:first-child {
  font-weight: bold;
  font-size: 20px;
}

.container img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 720px) {
  .container {
    grid-auto-rows: minmax(100px, auto);
  }
  .container img {
    object-fit: contain;
  }
  .description {
    display: block;
  }
}
