.image {
  width: 100%;
}

.imageBottom {
  width: 100%;
  margin-top: 8vw;
}

@media screen and (max-width: 640px) {
  .imageBottom {
    margin-top: 10px;
  }
}
