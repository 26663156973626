.container {
  display: grid;
  grid-gap: 10px;
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.container img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media screen and (min-width: 1600px) {
  .container {
    grid-template-columns: repeat(5, 1fr);  }
}

@media screen and (min-width: 1000px) and (max-width: 1599px) {
  .container {
    grid-template-columns: repeat(4, 1fr);  }
}

@media screen and (min-width: 641px) and (max-width: 999px) {
  .container {
    grid-template-columns: repeat(2, 1fr);  }
}

@media screen and (max-width: 640px) {
  .container {
    grid-template-columns: repeat(1, 1fr);  }
}