.container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  gap: 10px;
  grid-auto-rows: minmax(400px, auto);
  margin: 0 15%;
}

.container img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 720px) {
  .container {
    margin: 0;
    grid-auto-rows: minmax(100px, auto);
  }
  .container img {
    object-fit: contain;
  }
}
