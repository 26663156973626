.container {
  margin-top: 60px;
  width: 100%;
  display: flex;
}

.photos {
  width: 40%;
  margin: 0 5%;
}

.photos img {
  max-width: 100%;
  max-height: 100%;
}

.text {
  width: 40%;
  margin: 0 10% 0 0;
}

.textBox {
  margin-top: 60px;
  text-align: justify;
  line-height: 1.8;
  font-size: 20px;
}

.link {
  margin: 10px 0;
  text-align: left;
  line-height: 1;
  font-size: 20px;
}

.link a {
  text-decoration: none;
}

@media screen and (min-width: 1600px) {
  .textBox {
    margin-top: 80px;
    line-height: 2;
    font-size: 24px;
  }
  .link {
    margin: 15px 0;
    line-height: 1.6;
    font-size: 24px;
  }
}

@media screen and (max-width: 1200px) {
  .textBox {
    margin-top: 40px;
    line-height: 1.6;
    font-size: 16px;
  }
  .link {
    margin: 10px 0;
    line-height: 1;
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .textBox {
    margin-top: 30px;
    line-height: 1.4;
    font-size: 14px;
  }
  .link {
    margin: 10px 0;
    line-height: 1;
    font-size: 14px;
  }
}

@media screen and (max-width: 720px) {
  .textBox {
    margin-top: 10px;
    line-height: 1.4;
    font-size: 14px;
  }
  .link {
    margin: 10px 0;
    line-height: 1;
    font-size: 14px;
  }
  .container {
    flex-direction: column;
  }
  .photos {
    margin: 0;
    width: 100%;
  }
  .text {
    margin: 0 8%;
    width: 84%;
  }
}
