.container {
  margin: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.description {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}

.container img {
  margin: 10px 0;
  width: 15px;
  height: 25px;
  cursor: pointer;
}

.text {
  width: 80%;
}

.textBox {
  margin-top: 20px;
  text-align: justify;
  line-height: 1.6;
  font-size: 18px;
}

@media screen and (max-width: 640px) {
  .description {
    margin-top: 10px;
    font-size: 12px;
  }
  .header {
    font-size: 16px;
  }
  .container img {
    width: 9px;
    height: 15px;
    padding: 5px;
  }
  .container {
    margin: 15px 0;
  }
}
