.reelsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardContainer {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cardLogo {
  margin: 120px 0 40px 0;
  width: 300px;
}

.cardTitle {
  margin: 40px 0 20px 0;
  font-size: 40px;
  font-weight: bold;
}

.cardContent {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.cardItem iframe {
  height: 80vh;
}

@media screen and (max-width: 960px) {
  .reelsContainer {
    width: 100%;
  }
  .cardContainer {
    width: 100%;
  }
  .cardLogo {
    margin: 60px 0 20px 0;
    width: 100px;
  }
  .cardTitle {
    font-size: 20px;
    font-weight: bold;
  }
  .cardContent {
    grid-template-columns: repeat(2, 1fr);
  }
  .cardItem iframe {
    height: 70vh;
    margin: 0;
  }
}

@media screen and (max-width: 640px) {
  .reelsContainer {
    width: 100%;
  }
  .cardContainer {
    width: 90%;
  }
  .cardLogo {
    margin: 60px 0 20px 0;
    width: 100px;
  }
  .cardTitle {
    font-size: 20px;
    font-weight: bold;
  }
  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cardItem iframe {
    height: 90vh;
  }
}
