@keyframes skew {
  0% {
    transform: skewX(25deg);
  }
  100% {
    transform: skewX(-25deg);
  }
}

.container {
  width: 100%;
  display: flex;
  position: fixed;
  left: 0;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 3;
}

.container ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container ul li {
  text-align: center;
}

.container ul li span {
  display: block;
  color: #000000;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  background-color: #ffffff;
  padding: 8px;
  text-transform: uppercase;
  border: none;
  width: 100%;
}

.container ul span img {
  height: 12px;
  width: 12px;
  margin-left: 5px;
}

.expandTab {
  margin-right: 17px;
  font-weight: bold;
}

.newCollection {
  margin-right: 17px;
  font-weight: bold;
  color: #e00070 !important;
  animation: skew 1s infinite;
  transform: skew(25deg);
  animation-direction: alternate;
}

@media screen and (min-width: 961px) {
  .container {
    display: none;
  }
}
