.videoContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 83vh;
  margin: 10px 0;
}

.video {
  width: 100%;
}
